import { React, useEffect, useState } from 'react';
import SubmenuHeader from '../Headers/SubmenuHeader';
import { MainHeader } from '.';
import { useParams } from 'react-router';
import getSubMenu from '../../../Api/getSubMenu';
import SubMenu from '../MenuSections/SubMenu';
import getMenuOptions from '../../../Api/getMenuOptions';
import { setThemeWithSessionStorage } from '../../../Helpers/SessionStorage';



let MainMenu = () => {

  const [menuOptions, setMenuOptions] = useState([]);

  const [subMenuCatigories, setSubMenuCatigories] = useState([]);
  const { location } = useParams("");

  useEffect(() => {
    if (location) {
      setThemeWithSessionStorage(location)
    }
  }, [location]);


  useEffect(() => {
    if (menuOptions.length <= 0) {
      getMenuOptions(location)
        .then(response => {
          response.json().then(data => ({
            data: data,
            status: response.status
          }))
            .then(jsonResponse => {

              let menuOptionsFiltered = jsonResponse.data.filter(
                (v,i,a)=>a.findIndex(v2=>['MenuID'].every(k=>v2[k] ===v[k]))===i);
              
              setMenuOptions( menuOptionsFiltered );
              console.log(jsonResponse.data)
              loadItems(menuOptionsFiltered)



            })
        })
        .catch((error) => {
          console.log(error);
        });
    }
    const loadItems = (catigories) => {

      console.log(catigories)
      catigories.forEach(catigory => {

        getSubMenu(catigory.MenuID)
          .then(response => {
            response.json().then(data => ({
              data: data,
              status: response.status
            }))
              .then(jsonResponse => {
                setSubMenuCatigories(menuItems => [...menuItems, ...jsonResponse.data]);

              })
          })
          .catch((error) => {
            console.log(error);
          })
      });
    }


  },[]);


  return (
    <>
      <MainHeader />
      <div className='body'>
        <SubmenuHeader menuOptions={menuOptions} />
        <div className="menu-item">
          <SubMenu menuOptions={menuOptions} subMenuCatigories={subMenuCatigories} ></SubMenu>
        </div>
      </div>
    </>
  );
}

export default MainMenu;