import { useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import logo from '../../../../img/logo-1.png';
// import logo from '../../../../img/logo-blue.jpg';
import hamburger from '../../../../img/hamburger.svg';


let Header = ({turnOffBackBtn, enableSearch}) => {
    const navigation = useNavigate();
    const { location } = useParams();
    //TODO: fix this to enable search a better way
    enableSearch = location=="F020A9F9-524A-4F20-94B6-885995F5FDAA";
    const [showNavbar, setShowNavbar] = useState(false)
    let version;
    if (window.location.href.indexOf("v2") > -1) {
        version = "v2";
    }
    else {
        version = "v1";
    }

    const handleShowNavbar = () => {
        setShowNavbar(!showNavbar)
    }
    console.log(turnOffBackBtn)
    return (
        <div>
            <nav className={'navbar'}>
                <div className='container'>
                    <NavLink className='nav-link' onClick={() => navigation(-1)}>
                       {turnOffBackBtn
                    ?<></>  : "Back"}
                    </NavLink>
                    
                    <div className="menu-icon" onClick={handleShowNavbar}>
                        <img className='mainLogo' alt={"main-logo"} src={hamburger} />
                    </div>

                    <div className={`nav-elements  ${showNavbar && 'active'}`}>

                        <ul>
                            <li>
                                <NavLink className='nav-link' to={`/${version}/${location}/`}>
                                    Menu
                                </NavLink>
                            </li>
                            {enableSearch
                            ? 
                            <li>
                                <NavLink className='nav-link' to={`/${version}/${location}/Search`}>
                                    Search
                                </NavLink>
                            </li>
                            : <></>}
                            <li>
                                <NavLink className='nav-link' to={`/${version}/${location}/Sponsors`}>
                                    Sponsors
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className='nav-link' to={`/${version}/${location}/ContactUs`}>
                                    Contact
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>

    );
}

export default Header;