import { useNavigate, useParams } from "react-router";

const Table = ({ data }) => {
  const navigation = useNavigate();
  const { location } = useParams();


    return (
      <table>
        <tbody>
          <tr>
            <th className="tr-25">Menu Item Name</th>
            <th className="tr-20">CBD</th>
            <th className="tr-20">THC</th>
            <th className="tr-25">Feelings</th>
            <th className="tr-25">Helps With</th>
          </tr>
          {data.map((item) => (
            <tr key={item.MenuItemID} onClick={() => navigation(`/v2/${location}/999/items/${item.MenuItemID}`)}>
              <td className="tr-25">{item.MenuItemName}</td>
              <td className="tr-20">{item.CBG}</td>
              <td className="tr-20">{item.THC}</td>
              <td className="tr-25">{item.Feelings}</td>
              <td className="tr-25">{item.HelpsWith}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
  
  export default Table;