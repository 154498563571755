import React from 'react';
import Header from './Header';
import Footer from './Footer';
import MainContent from './MainContent';
import styles from '../../../MainWebsite.css';


let FrontPage = () => {

    return (
        <div className={styles.app}>
          <Header />
          <MainContent />
          <Footer />
        </div>
      );

}

export default FrontPage;