import getMenuOptions from "../Api/getMenuOptions";


export const setThemeWithSessionStorage = (location, setLocationName) => {

    const sessionKeyTheme = location + "-theme";
    const sessionKeyName = location + "-name";

    if (!location) {
        console.log("location not provided")
        return;
    }
    let locationTheme = window.sessionStorage.getItem(sessionKeyTheme);
    let locationName = window.sessionStorage.getItem(sessionKeyName);

    if (locationTheme) {
        console.log("retrieved from session storage " + locationTheme)
        document.getElementById("app-root").classList.add(locationTheme);
    
        if (setLocationName) {
            console.log("retrieved from session storage " + locationName)
            setLocationName(locationName)
            
        }
        return;
    }

    getMenuOptions(location)
        .then(response => {
            response.json().then(data => ({
                data: data,
                status: response.status
            }))
                .then(jsonResponse => {
                    console.log(jsonResponse.data)
                    console.log("retrieved thme from api " + jsonResponse.data[0].theme_name)
                    console.log("retrieved thme from api " + JSON.stringify(jsonResponse.data))
                    window.sessionStorage.setItem(sessionKeyTheme, jsonResponse.data[0].theme_name)
                    document.getElementById("app-root").classList.add(jsonResponse.data[0].theme_name)

                    let locationName = jsonResponse.data[0].LocationName;
                   window.sessionStorage.setItem(sessionKeyName, jsonResponse.data[0].LocationName)
                   if (setLocationName) {
                    console.log("retrieved from API " + locationName)
                    setLocationName(locationName)
                }
                })
        })
        .catch((error) => {
            console.log(error);
        })
}

