import { Col, Row } from 'react-bootstrap';
import MenuItem3 from './MenuItem3';

let SubMenu = ({ menuOptions, subMenuCatigories }) => {

  const getSubmenuDropdown = (menuId) => {

    return subMenuCatigories.map((item) => {
      if (item.MenuID === menuId) {
        return (

          <Row id={item.SubmenuID} key={item.SubmenuID} className="">
            <h5 className="menu-catigory-title">{item.SubmenuText}</h5>
            <Col className="col-count-amount">
              <MenuItem3 submenuId={item.SubmenuID}></MenuItem3>
            </Col>
          </Row>

        )
      }
    }
    )


  }

  const menuSection = menuOptions.map(menuOption => {
    let subMenu = getSubmenuDropdown(menuOption.MenuID)
    return (
      <div key={menuOption.MenuID} className="catigory-section">
        <a name={menuOption.MenuID}></a>
        <h4>{menuOption.DisplayText}</h4>
        {subMenu}
      </div>
    )
  })


  return (
    <>

      {menuSection}

    </>
  );
}

export default SubMenu;