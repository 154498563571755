import React from 'react';
import Image from '../../../img/MainWebsite/ForResturants.jpg';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

function ForRestaurants() {

  let navigate = useNavigate();

  return (
    <main >
        <Header />
        <div className={'mainContent '}>
            <div className={'column'}>
                <h2  className={'left red'}>VIZO MENU FOR YOUR RESTAURANT</h2>
                <h5 className={'left'}>Paper menus are limited by their dimensions, and they don't do justice to the hard work you put in to developing your menu items.</h5>
                <h5 className={'left'}>Vizo Menu showcases your menu items the way no other menu has before</h5>
                <h5 className={'left'}>We have an upselling algorithm tool that helps your staff earn higher tips and your company generates more revenue.</h5>
                <h2 className={'left red'}>VIZO MENU FOR YOUR SERVICE TEAM</h2>
                <h5 className={'left'}>The hospitality industry has a staggering turnover ratio of 70%-80%  annually.</h5>
                <h5 className={'left'}>Vizo Menu helps train your team and provides a consistent and superior guest experience.</h5>
                <h5 className={'left'}>Vizo Menu is accessible to your team both on-site and off-site via phone, PC, or tablet.</h5>
            </div>
            <div className={'column'}>
                <img src={Image} className={'page-image image-border'} alt="Image 2" />
                <button  className={' button-spacing chrome-background red-link'} onClick={()=>{navigate("/Contact-Us")}}>
                CONTACT US TO SCHEDULE A DEMO
                </button>
            </div>
      </div>
      <Footer/>
    </main>
  );
}

export default ForRestaurants;