// Modal.js
import React from 'react';
import '../../../Styling/DefaultModal.css'; // Optional: for styling

const DefaultModal = ({ handleClose, successMessage, show, children }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  const SUCCESSMESSAGE = "Thanks for your interest. We will respond to you shortly";
  const FAILEDMESSAGE = "Something went wrong. Please try again soon.";

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <p>{successMessage?SUCCESSMESSAGE:FAILEDMESSAGE}</p>
        <button className="btn btn-primary" onClick={handleClose}>Close</button>
      </section>
    </div>
  );
};

export default DefaultModal;