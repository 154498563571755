import React from 'react';

let Footer = () => {
    return (
        <footer className='py-0 footer'>
            <div className=''>
                <p className=' text-left'>
                    Copyright &copy; LazyLoris 2023 & Vizomenu
                </p>
            </div>
        </footer>
    );
}
export default Footer;