export const CODE_PARAM = "code";

//get client https://vizomenuapi.azurewebsites.net/api/{clientID}?code=ile2pd0d5dQTJmqCWmW2T-9A2C4H0VuAtYu88uulZTipAzFuRTWNog==
export const BASE_URL = 'https://vizomenuapi.azurewebsites.net/api/';

export const CLIENT_CODE_BASE = `${BASE_URL}`;
export const CLIENT_CODE_KEY = 'ile2pd0d5dQTJmqCWmW2T-9A2C4H0VuAtYu88uulZTipAzFuRTWNog==';

//get menu https://vizomenuapi.azurewebsites.net/api/Location/{inLocid}?code=71eS5WzzYlxyIIlY8DgQPjWfBTB-nyLlTXUxeib5FCg2AzFupK_hrQ==
export const MENU_API_BASE = `${BASE_URL}Location/`;
export const MENU_API_KEY = 'weBbbmEsE72c3hyDPAJ2ZzUn7Msve1YnX2icDU5syAvUAzFuvcqXFw==';

//get sub menu https://vizomenuapi.azurewebsites.net/api/Menu/{inMenuID}?code=tEkcvuDjUfJupY4ZAoeWNmMlhdISEEtAz0tMGNQR7GUGAzFuVEo7zA==
export const SUBMENU_API_BASE = `${BASE_URL}Menu/`;
export const SUBMENU_API_KEY = 'Lovm9PyapgA-y2xEuCkVNlFtHyHtYsYE_v8YVyfQgO-ZAzFu0IOvbQ==';

//get items https://vizomenuapi.azurewebsites.net/api/subMenu/{inSubMenuID}?code=Si4XAoJVcafk_5Zi74dIyl0aO36sX5UN4bYu8DiSkuQhAzFuZJ8iGg==
export const ITEMS_API_BASE = `${BASE_URL}subMenu/`;
export const ITEMS_API_KEY = 'Si4XAoJVcafk_5Zi74dIyl0aO36sX5UN4bYu8DiSkuQhAzFuZJ8iGg==';

//https://vizomenuapi.azurewebsites.net/api/item/{inItemID}?code=60pXseKQ7tY8MA7L9fe1QoS8HMah07gXrFrtME5cYwjXAzFuzPf11Q==
export const SINGLE_ITEMS_API_BASE = `${BASE_URL}item/`;
export const SINGLE_ITEMS_API_KEY = '60pXseKQ7tY8MA7L9fe1QoS8HMah07gXrFrtME5cYwjXAzFuzPf11Q==';

//https://vizomenuapi.azurewebsites.net/api/contact?code=yFrUgwgW8HDlcETrjFB_x6DOhfmlaGXzve4xZlQn5ChnAzFuF3vZ1g==
export const CONTACT_US_API_BASE = `${BASE_URL}contact/`;
export const CONTACT_US_API_KEY = 'yFrUgwgW8HDlcETrjFB_x6DOhfmlaGXzve4xZlQn5ChnAzFuF3vZ1g==';

//https://vizomenuapi.azurewebsites.net/api/recommend/{locid}/{inItemID}?code=OGgaOxKIGEtDyhkbpIaO8s5n4OCcmK-8-g-a6PUxD1QJAzFucSJL_g==
export const RECOMMENDED_ITEMS_API_BASE = `${BASE_URL}recommend/`;
export const RECOMMENDED_ITEMS_API_KEY = 'OGgaOxKIGEtDyhkbpIaO8s5n4OCcmK-8-g-a6PUxD1QJAzFucSJL_g==';

//https://vizomenuapi.azurewebsites.net/api/ads/{inLocid}?code=MHBcB7xTlmpRsfDoV0tnCwLNreZD2IesEcKnkGjawVy-AzFu4OR7gg==
export const GET_LOCATION_ADS_API_BASE = `${BASE_URL}ads/`;
export const GET_LOCATION_ADS_API_KEY = 'MHBcB7xTlmpRsfDoV0tnCwLNreZD2IesEcKnkGjawVy-AzFu4OR7gg==';

//https://vizomenuapi.azurewebsites.net/api/ViewedAd/{inLocid}/{adID}?code=moSCdGuYuu0sB1L8_EmcUH6UnkYQH4dPy7Fqewx-W1DPAzFu-edTNQ==
export const TRACK_VIEWED_AD_API_BASE = `${BASE_URL}ViewedAd/`;
export const TRACK_VIEWED_AD_API_KEY = `moSCdGuYuu0sB1L8_EmcUH6UnkYQH4dPy7Fqewx-W1DPAzFu-edTNQ==`;

//https://vizomenuapi.azurewebsites.net/api/profiles/{locid}?code=-DWPGNNTix7c8CNOzUvogskMwpBByyamqy2MGeJeu5TWAzFuNs6N0w%3D%3D
export const SEARCH_CRITERIA_API_BASE = `${BASE_URL}profiles/`;
export const SEARCH_CRITERIA_API_KEY = `-DWPGNNTix7c8CNOzUvogskMwpBByyamqy2MGeJeu5TWAzFuNs6N0w==`;