import { useNavigate, useParams } from 'react-router-dom';
import { MainHeader } from '.';
import { useEffect, useState } from 'react';
import {  setThemeWithSessionStorage } from '../../../Helpers/SessionStorage';


let HomePage = () => {

  const navigation = useNavigate();
  const { location } = useParams();
  const [locationName, setLocationName] = useState("");

  useEffect(() => {
    if (location) {
      setThemeWithSessionStorage(location, setLocationName)
    }
  }, [location]);

  const navigateToMenu = () => {
    navigation(`/v1/${location}/menu`);
  }



  return (
    <div>
      <MainHeader />
      <div className="body">
        <div className="background-section">
          <div className="home-page-section">
            <h4>Welcome to {locationName}</h4>
            <h5>Click the image below to view the menu </h5>
            <img className="home-page-img" src='https://visomenuimages.blob.core.windows.net/vizomenu/Vizo-Menu-Slogan-White-Background.jpg' onClick={() => navigateToMenu()} alt="page text" />
          </div>
        </div>
      </div>
    </div>
  );

}

export default HomePage;