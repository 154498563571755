import React from 'react';
import MiddleImage from '../../../img/MainWebsite/HomePage.jpg';
import RedLogo from '../../../img/MainWebsite/RedSilverLogo.png';
import BlueLogo from '../../../img/MainWebsite/BlueSilverLogo.png';
import GreenLogo from '../../../img/MainWebsite/GreenChromeLogo.png';
import { Link, useNavigate } from 'react-router-dom';

function MainContent() {

  const navigate = useNavigate();
  return (
    <main >
      <link rel="preconnect" href="https://fonts.googleapis.com"/>
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
<link href="https://fonts.googleapis.com/css2?family=Courgette&display=swap" rel="stylesheet"></link>
        <h1 className="font-courgette">Changing the way you look at menus</h1>
        <div className={''}>
                <img src={MiddleImage} className={' home-image image-border'} alt="Image 2" />
            </div>
        <div className={'mainContent'}>
            <div className={'column'}>
                <img src={RedLogo} className={'vizo-logo-image image-border'} alt="Image 1" />
                <button className={'button-spacing chrome-background red-link'} onClick={()=>
                  navigate("For-Restaurants")}>
                  Click Here For Restaurants
                </button>
            </div>
            <div className={'column'}>
                <img src={BlueLogo} className={'vizo-logo-image image-border'} alt="Image 3" />
                <button className={'button-spacing chrome-background blue-link'} onClick={()=>
                  navigate("For-Stores")}> 
                  Click Here For Stores
                </button>
            </div>
            <div className={'column'}>
                <img src={GreenLogo} className={'vizo-logo-image image-border'} alt="Image 4" />
                <button className={'button-spacing chrome-background green-link'} onClick={()=>
                  navigate("For-Dispensaries")}> 
                  Click Here For Dispensaries
                </button>
            </div>
      </div>
    </main>
  );
}

export default MainContent;