import { CONTACT_US_API_BASE, CODE_PARAM, CONTACT_US_API_KEY } from '../Constants/APIConstants';

const postContactUs = (parameters) => {
    let url = new URL(`${CONTACT_US_API_BASE}`);
    url.searchParams.append(CODE_PARAM, CONTACT_US_API_KEY);

    return fetch(url, {
        
            method: "post",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
          
            //make sure to serialize your JSON body
            body: JSON.stringify({ ...parameters  })
         
    });
}

export default postContactUs;