import { createPortal } from "react-dom"


let AdModal = ({ setClick, imageSource, adID }) => {
    let handleClick = () => {
        setClick()
    }

    return     createPortal(
        <>
          <div className="adModal">
            <img onClick={() => { handleClick() }} alt={adID} src={imageSource} />
        </div>
        </>,
        document.getElementById('root-modal')
      )
}
export default AdModal;