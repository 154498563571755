import React, { useState } from 'react';
import postContactUs from '../../Api/postContactUs'
import Header from './Header';
import Footer from './Footer';
import DefaultModal from '../MenuComponents/Modals/DefaultModal';


let MainContactUs = () => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [modalMessage, setModalMessage] = useState(true);
  const [formStatus, setFormStatus] = useState('Send');
  const [show, setShow] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setFormStatus('Submitting...');
    let conFom = {
      ContactName: name,
      email: email,
      message: message,
      LocationID: "",
      LocationGUID: ""
    }
    console.log(conFom)

    postContactUs(conFom)
    .then(response => {
      if(!response.ok)
        throw new Error(response.status);
      else return response;
    })
      .then(() => {
        setFormStatus('Send');
        setName("");
        setEmail("");
        setMessage("");
        setLoading(false);
        setModalMessage(true);
        setShow(true);
      })

      .catch((error) => {
        setLoading(false);
        setFormStatus('Send');
        setModalMessage(false);
        setShow(true);
        console.log(error);
      })

  }



  return (
    <>
      <Header />

      <div className="background-section">
        <h2 className="mb-3">Contact Us </h2>
        <form onSubmit={onSubmit}>
          <div className="mb-3">
            <label className="form-label" htmlFor="name">
              Name
            </label>
            <input className="form-control" type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} required />
          </div>
          <div className="mb-3">
            <label className="form-label" htmlFor="email">
              Email
            </label>
            <input className="form-control" type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
          </div>
          <div className="mb-3">
            <label className="form-label" htmlFor="message">
              Message
            </label>
            <textarea className="form-control" id="message" value={message} onChange={(e) => setMessage(e.target.value)} required />
          </div>
          <button className="btn btn-primary" disabled={loading} type="submit">
            {formStatus}
          </button>
        </form>
      </div>
      <DefaultModal show={show} successMessage={modalMessage} handleClose={()=>setShow(false)} />
      <Footer />
    </>
  );
}

export default MainContactUs;