import React from 'react';
import Image from '../../../img/MainWebsite/OurMenu.jpg';
import {  useNavigate } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

function OurMenus() {

 let navigate = useNavigate();

  return (
    <main >
                <Header />
        <div className={'mainContent'}>
            <div className={'column'}>
              <h2 className={'blue left'}>OUR MENUS</h2>
              <h5 className={'left'}> Vizo Menu makes menus for:</h5>

              <ul className={"two-columns"}>
                <li className={'left'}><h5>Food</h5></li>
                <li className={'left'}><h5>Wine</h5></li>
                <li className={'left'}><h5>Cocktails</h5></li>
                <li className={'left'}><h5>Beer</h5></li>
                <li className={'left'}><h5>Spirits</h5></li>
                <li className={'left'}><h5>Cigars</h5></li>
                <li className={'left span-two'}><h5> and whatever else you can imagine</h5></li>
              </ul>
              <h2 className={'blue left'}>CAPTIVATING YOUR CUSTOMERS AND HIGHLITING YOUR OFFERINGS</h2>

              <h5 className={'left'}> We are NOT critics. Critics often try to draw attention to themselves with negative commentary.</h5>
              <h5 className={'left'}> Our mission is simple and two-fold:</h5>
              <ol className={'number-bullets'}>
                <li className={'left'}><h5> We only emphasize the positives</h5></li>
                <li className={'left'}><h5> We want to help you sell more.</h5></li>
              </ol>
                <h5 className={'left'}> We accentuate the items you offer with</h5>
              <ul>
                <li className={'left'}><h5> Memorable background stories</h5></li>
                <li className={'left'}><h5> Mouthwatering descriptions</h5></li>
                <li className={'left'}><h5> Insightful expert reviews and ratings</h5></li>
                <li className={'left'}><h5> Useful wine paring suggestions</h5></li>
                <li className={'left'}><h5> Critical allergy notices</h5></li>

              </ul>
            </div>
            <div className={'column'}>
                <img src={Image} className={'page-image image-border'} alt="Image 2" />
                <button className={' button-spacing chrome-background blue-link'} onClick={()=>{navigate("/Contact-Us")}}>
                  CONTACT US TO SCHEDULE A DEMO
                
                </button>
            </div>
      </div>
      <Footer/>
    </main>
  );
}

export default OurMenus;