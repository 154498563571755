import React from 'react';
import Image from '../../../img/MainWebsite/AboutUs.jpg';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

function AboutUs() {

  let navigate = useNavigate();

  return (
    <main >
                  <Header />
        <div className={'mainContent'}>
            <div className={'column'}>
            <h2 className={"red left"}>ABOUT US</h2>
            <h5 className={'left'}>At Vizo Menu, we are "outside of the box" thinkers and  changing the way you look at menus.  </h5>
            <h5  className={'left'}>Vizo Menu Creates custom digital smart menus that give your customers the confidence to make educated purchases.</h5>
            <h5 className={'left'}>Vizo Menu is a web-based application that requires no expensive hardware (easily accessible from a tablet or phone).</h5>
            <h5 className={'left'}>Vizo Menu remains anonymous and behind the scenes. Your customers only see your brand.</h5>
            <h2 className={"red left"}> WORKING FOR YOU</h2>
            <h5 className={'left'}>You can have an unlimited number of menus. There are no restrictions to the number of menu changes you make. There are multiple themes, and you select your color scheme and layout. </h5>
            <h5 className={'left'}>Vizo Menu can generate revenue with unlimited sponsors and save money on marketing expenses with unlimited promotions. </h5>
            <h5 className={'left'}>You can manage multiple locations/departments and make instant global changes. </h5>
            </div>
            <div className={'column'}>
                <img src={Image} className={'page-image image-border'} alt="Image 2" />
                <button  className={' button-spacing chrome-background red-link'} onClick={()=>{navigate("/Contact-Us")}}>
                CONTACT US TO SCHEDULE A DEMO
                </button>

            </div>
      </div>
      <Footer/>
    </main>
  );
}

export default AboutUs;