import { useEffect, useState } from 'react';
import getItems from '../../../Api/getItems';
import { Link } from 'react-router-dom';

let MenuItem3 = ({ submenuId }) => {

    const [items, setItems] = useState([]);


    useEffect(() => {
        console.log(submenuId)
        getItems(submenuId)
            .then(response => {
                response.json().then(data => ({
                    data: data,
                    status: response.status
                }))
                    .then(jsonResponse => {
                        setItems(jsonResponse.data);
                        console.log(jsonResponse.data)
                    })
            })
            .catch((error) => {
                console.log(error);
            })

    }, []);

    const catigoryItems = items.map(item => {
        return (
            <Link className="text-decoration-none" to={`${item.MenuItemID}`}>
                <div key={item.MenuItemID} className="items">
                <p className="menu-item-title">{item.displayName}</p>
                {/* <p className="menu-item-title">{item.description}</p> */}
                    <p className="menu-item-price">{item.price}</p>
                    <div class = "spacer" ></div>
                </div>
            </Link>

        )
    })

    return (
        <>
            {catigoryItems}
        </>
    );
}

export default MenuItem3;