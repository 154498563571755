import React from 'react';

function Footer() {
  return (
    <footer className={'footer chrome-background-1'}>
      <p>&copy; 2024 Vizo Menu. All rights reserved.</p>
    </footer>
  );
}

export default Footer;