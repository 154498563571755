import React from 'react';
import Image from '../../../img/MainWebsite/ForDispensaries.jpg';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

function ForRestaurants() {

  let navigate = useNavigate();

  return (
    <main >
        <Header />
        <div className={'mainContent '}>
            <div className={'column'}>
                <h2  className={'left green'}>VIZO MENU FOR DISPENSARIES</h2>
                <h5 className={'left'}>Medical and recreational marijuana is gaining traction in America with more states legalizing its use and more dispensaries opening across the country.</h5>
                <h5 className={'left'}>There are 700+ flower strains and the number is growing. When you consider the wide range of edibles and vaporizers, your customers need assistance navigating the many options available.</h5>
                <h5 className={'left'}>Vizo Menu can be viewed by tablet or phone and provides informative yet concise information to put your customers at ease when making purchases.</h5>
                <h2 className={'left green'}>VIZO MENU FOR YOUR STAFF “BUDTENDERS”</h2>
                <h5 className={'left'}>With the staggering number of options available, your team needs help guiding your customers. Vizo Menu gives your staff the tools required to make your customers feel comfortable making more purchases and higher-priced products.</h5>
            </div>
            <div className={'column'}>
                <img src={Image} className={'page-image image-border'} alt="Image 2" />
                <button  className={' button-spacing chrome-background green-link'} onClick={()=>{navigate("/Contact-Us")}}>
                CONTACT US TO SCHEDULE A DEMO
                </button>
            </div>
      </div>
      <Footer/>
    </main>
  );
}

export default ForRestaurants;