import React from 'react';
import Image from '../../../img/MainWebsite/ForStores.jpg';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

function ForStores() {

  const navigate = useNavigate();

  return (
    <main >
        <Header />
        <div className={'mainContent'}>
            <div className={'column'}>
                <h2 className={'blue left'}>VIZO MENU FOR YOUR STORE AND YOUR SHOPPERS</h2>
                <h5 className={'left'}>A menu by simple definition is a detailed list of options offered to a customer. Restaurants have menus, why isn't there a menu for shoppers in a store?  </h5>
                <h5 className={'left'}>Vizo Menu helps reduce your labor costs while providing the guidance your customers always want. </h5>
                <h5 className={'left'}>Vizo Menu gives your customers the information that they need to feel comfortable making purchases with no doubts and no remorse. </h5>
                <h2 className={'blue left'}>VIZO MENU FOR YOUR STORE TEAM</h2>
                <h5 className={'left'}>Can you really expect your team to know all your 1,000s of products and provide accurate information to your customers?</h5>
                <h5 className={'left'}>Vizo Menu can give your staff an application that provides on-site and off-site training, ensures better customer service and initiates increased sales.</h5>
            </div>
            <div className={'column'}>
                <img src={Image} className={'page-image image-border'} alt="Image 2" />
                <button className={'button-spacing chrome-background blue link'} onClick={()=>{navigate("Contact-Us")}}>
                CONTACT US TO SCHEDULE A DEMO
                </button>
            </div>
      </div>
      <Footer/>
    </main>
  );
}

export default ForStores;