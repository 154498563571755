import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { MainHeader } from '.';
import { useEffect, useState } from 'react';
import { setThemeWithSessionStorage } from '../../../Helpers/SessionStorage';
import logo from '../../../../img/logo-1.png';
import bannerSmall from '../../../../img/pumpkin-640x135.jpg';
import bannerLarge from '../../../../img/pumpkin-1280x541.jpg';
import getMenuOptions from '../../../Api/getMenuOptions';

let MenuCatigoriesMain = () => {

    const { location } = useParams();
    const [locationName, setLocationName] = useState("");

    const [menuOptions, setMenuOptions] = useState([]);


    useEffect(() => {
        if (location) {
            setThemeWithSessionStorage(location, setLocationName)
        }
    }, [location]);

    useEffect(() => {
        if (menuOptions.length <= 0) {
            getMenuOptions(location)
                .then(response => {
                    response.json().then(data => ({
                        data: data,
                        status: response.status
                    }))
                        .then(jsonResponse => {

                            let menuOptionsFiltered = jsonResponse.data.filter(
                                (v, i, a) => a.findIndex(v2 => ['MenuID'].every(k => v2[k] === v[k])) === i);

                            setMenuOptions(menuOptionsFiltered);
                            console.log(menuOptionsFiltered)
                            console.log(jsonResponse.data)


                        })
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [])


    const setCatigories = () => {
        console.log("in set catigories");
        return menuOptions.map((item) => {

            return (
                <div className="menu-catigory-wrapper">
                   { item.menuButtonImage ?
                    <NavLink className='nav-link' to={`/v2/${location}/${item.MenuID}`}>
                        <img className="menu-catigory-images" 
                        src={item.menuButtonImage}
                         alt="page text" />
                    </NavLink>
                    :
                    <div className="menu-catigory-placeholder">
                        <NavLink className='nav-link ' to={`/v2/${location}/${item.MenuID}`}>
                        <h2 className="menu-catigory-placeholder-text">{item.DisplayText}</h2>
                        </NavLink>
                    </div>
                
                }
                </div>
            )
        }
        )
    }


    return (
        <div>
            <MainHeader turnOffBackBtn={true}/>
            <div className="body catigory-font-color">
                {/* <img className='mainLogo' alt={"main-logo"} src={logo} /> */}
                <h4>Welcome to {locationName}</h4>
                <h5>Click a category to get started!</h5>
                {setCatigories()}
            </div>


        </div>
    );

}

export default MenuCatigoriesMain;