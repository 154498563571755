import React from 'react';
import './Menu.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './main/Styling/Navbar.css'
import { Route, Routes } from "react-router-dom";
import { HomePage, Footer, MainMenu, ItemPage, ContactUs, NoMatch } from './main/Components/MenuComponents/MainPages';
import ImageSlider from './main/Components/MenuComponents/ImageSlider/ImageSlider';
import SponsorsPage from './main/Components/MenuComponents/MainPages/SponsorsPage';
import SearchPage from './main/Components/MenuComponents/MainPages/SearchPage';
import MenuCatigoriesMain from './main/Components/MenuComponents/MainPages/MenuCatigoriesMain';
import MenuSubCatigory from './main/Components/MenuComponents/MainPages/MenuSubCatigory';
import ItemList from './main/Components/MenuComponents/MainPages/ItemList';
import FrontPage from './main/Components/MainWebsite/FrontPage';
import AboutUs from './main/Components/MainWebsite/AboutUs';
import OurMenus from './main/Components/MainWebsite/OurMenus';
import ForRestaurants from './main/Components/MainWebsite/ForRestaurants';
import ForDispensaries from './main/Components/MainWebsite/ForDispensaries';
import ForStores from './main/Components/MainWebsite/ForStores';
import MainContactUs from './main/Components/MainWebsite/MainContactUs';


function App() {

  return (
    <div id="app-root" className='App'>
      <Routes>
      <Route
  path="*"
  element={<NoMatch status={404} />}
/>
        <Route path='/' element={ <FrontPage /> } />
        <Route path='/About-Us' element={ <AboutUs /> } />
        <Route path='/Our-Menus' element={ <OurMenus /> } />
        <Route path='/For-Restaurants' element={ <ForRestaurants /> } />
        <Route path='/For-Dispensaries' element={ <ForDispensaries /> } />
        <Route path='/For-Stores' element={ <ForStores /> } />
        <Route path='/Contact-Us' element={ <MainContactUs /> } />
        <Route path='/v2/:location' element={<MenuCatigoriesMain />} />
        <Route path='/v2/:location/:subcatigory' element={<MenuSubCatigory />} />
        <Route path='/v2/:location/:subcatigory/items' element={<ItemList />} />
        <Route path='/v2/:location/:subcatigory/items/:item' element={<ItemPage />} />
        <Route path='/v2/:location/ContactUs' element={<ContactUs />} />
        <Route path='/v2/:location/Sponsors' element={<SponsorsPage />} />
        <Route path='/v2/:location/Search' element={<SearchPage />} />
        <Route path='/v1/:location' element={<HomePage />} />
        <Route path='/v1/:location/menu' element={<MainMenu />} />
        <Route path='/v1/:location/menu/:item' element={<ItemPage />} />
        <Route path='/v1/:location/ContactUs' element={<ContactUs />} />
        <Route path='/v1/:location/Sponsors' element={<SponsorsPage />} />
      </Routes>
      <ImageSlider />
    </div>
  );
}

export default App;
