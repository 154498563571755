import { NavLink, useParams } from 'react-router-dom';
import { MainHeader } from '.';
import { useEffect, useState } from 'react';
import { setThemeWithSessionStorage } from '../../../Helpers/SessionStorage';
import bannerSmall from '../../../../img/pumpkin-640x135.jpg';
import getSubMenu from '../../../Api/getSubMenu';

let MenuSubCatigory = () => {

    const { location, subcatigory } = useParams();
    const [subMenuCatigories, setSubMenuCatigories] = useState([]);


    useEffect(() => {
        if (location) {
            setThemeWithSessionStorage(location)
        }
    }, [location]);

    useEffect(() => {
        console.log(subcatigory)
            getSubMenu(subcatigory)
                .then(response => {
                    response.json().then(data => ({
                        data: data,
                        status: response.status
                    }))
                        .then(jsonResponse => {
                            console.log(jsonResponse.data)
                            setSubMenuCatigories(menuItems => [...menuItems, ...jsonResponse.data]);
                            

                        })
                })
                .catch((error) => {
                    console.log(error);
                });
     
    }, [])

    const getSubmenuOptions = () => {

        return subMenuCatigories.map((item) => {
            console.log(item)
            if (item.MenuID == subcatigory) {
                return (

                    <div className="menu-catigory-wrapper">
                        {item.buttonImagePath? 
                        <NavLink className='nav-link' to={`/v2/${location}/${item.SubmenuID}/items`}>
                            <img className="menu-catigory-images"
                        src={item.buttonImagePath} 
                        alt="page text" />
                            
                        </NavLink>
                        :
                        <div className="menu-catigory-placeholder">
                            <NavLink className='nav-link ' to={`/v2/${location}/${item.SubmenuID}/items`}>
                            <h2 className="menu-catigory-placeholder-text">{item.SubmenuText}</h2>
                            </NavLink>
                        </div>
            }
                    </div>

                )
            }
        }
        )
    }




    return (
        <div>
            <MainHeader />
            <div className="body">
                {getSubmenuOptions()}
            </div>
        </div>
    );

}

export default MenuSubCatigory;