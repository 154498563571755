
let SubmenuHeader = ({ menuOptions }) => {

  return (
    <div className={'border-rounded submenu-header '}>

      {menuOptions && menuOptions.map(option => {
        return (
          <div key={option.MenuID} className="submenu-item">
            <a className="text-decoration-none" href={`#${option.MenuID}`} >
              <article key={option.MenuID}>
                <h4 className="submenu-titles">{option.DisplayText}</h4>
              </article>
            </a>
          </div>
        )
      })}

    </div>


  );
}

export default SubmenuHeader;