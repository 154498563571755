import React, { useState, useEffect } from 'react';
import { Footer, MainHeader } from '.';
import { useParams } from 'react-router-dom';
import { setThemeWithSessionStorage } from '../../../Helpers/SessionStorage';
import Table from '../Shared/Table';
import getSearchCriteria from '../../../Api/getSearchCriteria';



let SearchPage = () => {
  const { location } = useParams();
  const [searchItems, setSearchItems] = useState([]);


    const [query, setQuery] = useState("");
  const keys = ["Feelings", "HelpsWith",  "MenuItemName", "THC", "CBG"];
  const Search = (searchItems) => {
    return searchItems.filter((item) =>
      keys.some((key) => item[key].toLowerCase().includes(query))
    );
  };
  



  useEffect(() => {
    console.log(location)
    if (location) {
      setThemeWithSessionStorage(location) 
      }

      getSearchCriteria(location)
      .then(response => {
        response.json().then(data => ({
          data: data,
          status: response.status
        }))
          .then(jsonResponse => {
            console.log(jsonResponse.data)
            setSearchItems(jsonResponse.data)
          })
      })
      .catch((error) => {
        console.log(error);
      })

  }, [location]);

  return (
    <>
      <MainHeader />

      <div className="background-section">
        <h2 className="mb-3">Search </h2>

       <input
        className="search-input"
        placeholder="Search..."
        onChange={(e) => setQuery(e.target.value.toLowerCase())}
      />
    {<Table data={Search(searchItems)} />}
      </div>
      <Footer />
    </>
  );
}

export default SearchPage;