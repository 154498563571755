import React, { useEffect, useState } from 'react';
import AdModal from '../Modals/AdModal';
import getAdBanners from '../../../Api/getAdBanners';
import { useLocation } from 'react-router';
import sendViewedAdClick from '../../../Api/sendViewedAdClick';
import { setThemeWithSessionStorage } from '../../../Helpers/SessionStorage';
import { TIME_TO_SWITCH_ADS_5SEC, TIME_TO_SWITCH_ADS_10SEC } from '../../../Constants/Constants';


let ImageSlider = () => {

  const [index, setIndex] = useState(0);
  const [modalVisiblity, setmodalVisiblity] = useState(false);
  const [adBannerImages, setAdBannerImages] = useState([]);

  var location = useLocation();
  location = location.pathname.split('/')[2];

  useEffect(() => {
    console.log(location)
    setThemeWithSessionStorage(location)
    if (location !== undefined) {
      getAdBanners(location)
        .then(response => {
          response.json().then(data => ({
            data: data,
            status: response.status
          }))
            .then(jsonResponse => {

              setAdBannerImages(jsonResponse.data);
              console.log(jsonResponse.data)
            })
        })
        .catch((error) => {
          console.log(error);
        })
    }
  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (modalVisiblity === false) {

        if (index >= adBannerImages.length - 1) {
          setIndex(0);
        }
        else {
          setIndex(index + 1)
        }
      }
    }, TIME_TO_SWITCH_ADS_10SEC)

    return () => clearInterval(intervalId);
  }, [index, adBannerImages, modalVisiblity])

  const displayAd = (adID) => {
    if (modalVisiblity === true) {
    }

    sendViewedAdClick(location, adID);
    setmodalVisiblity(!modalVisiblity);
  }

  return (
    <>
      {modalVisiblity ? (<AdModal imageSource={adBannerImages[index]?.ad_ImagePath}
        adID={adBannerImages[index]?.adID}
        setClick={() => setmodalVisiblity(false)} />) 
        : null}
      {adBannerImages.length > 0 ?
        (<div className="image-slider">
          <img className="adBannerImages"
            onClick={() => { displayAd(adBannerImages[index]?.adID) }}
            src={adBannerImages[index]?.ad_BannerPath} />
        </div>)
        : <></>
      }
    </>
  );

}


export default ImageSlider;