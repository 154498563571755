import { React, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { MainHeader } from '.';
import getSingleItems from '../../../Api/getSingleItems'
import getRecommendedItems from '../../../Api/getRecommendedItems';
import { setThemeWithSessionStorage } from '../../../Helpers/SessionStorage';
import { Link } from 'react-router-dom';

let MenuItem = () => {
  let { item, location, subcatigory } = useParams();
  console.log(item)
  const [menuItem, setMenuItem] = useState([]);
  const [recommendedItems, setrecommendedItems] = useState([]);

  useEffect(() => {
    if (location) {
      setThemeWithSessionStorage(location)
    }
  }, [location]);

  useEffect(() => {

    getSingleItems(item)
      .then(response => {
        response.json().then(data => ({
          data: data,
          status: response.status
        }))
          .then(jsonResponse => {
            setMenuItem(jsonResponse.data);
            console.log(jsonResponse)
          })
      })
      .catch((error) => {
        console.log(error);
      })


    getRecommendedItems(location, item)
      .then(response => {
        response.json().then(data => ({
          data: data,
          status: response.status
        }))
          .then(jsonResponse => {
            setrecommendedItems(jsonResponse.data);
            console.log(jsonResponse.data)
          })
      })
      .catch((error) => {
        console.log(error);
      })

  }, [location, item]);

  const displayImage = () => {
    let pathBoolean = menuItem.imagePath;
    if (!pathBoolean){
       return <>
        <p className="single-item-title">
          {menuItem.displayName}
        </p>
        <p className="single-item-description">
          {menuItem.description}
        </p>
       </>  
    }
    else  {
      return (<img src={menuItem.imagePath} className="responsive-image" />)
    }
  }
  const displayRecommendedItems = () => {
    //need to do a check on if the images returned have no images to remove the recommended items
    var filterdRecommendations = recommendedItems.filter(item =>{

      if (item.HasImage === "Y")
        return item;
      else 
        return false;
    })


    if (filterdRecommendations.length ===0)
      return <></>

    return (
     <div className="background-section">
      <p className="single-item-title">Try one of our other favorites!</p>
        <div className="suggestion-images" >
          {filterdRecommendations.map(items => {
            return (
            <Link to={`/v2/${location}/${subcatigory}/items/${items.MenuItemID}`}>
                  <img src={items.imagePath} alt={`image of ${items.MenuItemID}`} className="responsive-image" /> 
            </Link>
          )
          })}
        </div>
      </div>
    
    )
  }


  return (
    <>
      <MainHeader />
      <section className="body">

        <>
          <div >
            <div className="background-section">
              {/* <h2>Selected Item</h2> */}
              {displayImage()}
            </div>
            {displayRecommendedItems()}
          </div>
        </>
      </section>
    </>
  );
}

export default MenuItem;