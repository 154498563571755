import React, { useState, useEffect } from 'react';
import { MainHeader } from '.';
import { useParams } from 'react-router-dom';
import getAdBanners from '../../../Api/getAdBanners';


let SponsorsPage = () => {
  const { location } = useParams();
  const [listOfAds, setListOfAds] = useState([]);


  useEffect(() => {
    console.log(location)
    if (location !== undefined) {
      getAdBanners(location)
        .then(response => {
          response.json().then(data => ({
            data: data,
            status: response.status
          }))
            .then(jsonResponse => {

              setListOfAds(jsonResponse.data);
              console.log(jsonResponse.data)
            })
        })
        .catch((error) => {
          console.log(error);
        })
    }
  }, [])

  const displayListOfAds = () => {

    return listOfAds.map((adItem) => {

      return (

        <div className="background-section">
          <img src={adItem.ad_BannerPath} className="responsive-image" />
          {/* <img src={adItem.ad_ImagePath} className="responsive-image" /> */}
        </div>

      )


    }
    )
  }

  return (
    <>
      <MainHeader />

      {displayListOfAds()}

    </>
  );
}

export default SponsorsPage;