import { useEffect, useState } from 'react';
import getItems from '../../../Api/getItems';
import { useNavigate, useParams } from 'react-router-dom';
import { setThemeWithSessionStorage } from '../../../Helpers/SessionStorage';
import { MainHeader } from '.';

let ItemList = () => {
    const navigation = useNavigate();
    const { location, subcatigory } = useParams();
    const [items, setItems] = useState([]);

    useEffect(() => {
        if (location) {
            setThemeWithSessionStorage(location)
        }
    }, [location]);



    useEffect(() => {
        console.log(subcatigory)
        getItems(subcatigory)
            .then(response => {
                response.json().then(data => ({
                    data: data,
                    status: response.status
                }))
                    .then(jsonResponse => {
                        setItems(jsonResponse.data);
                        console.log(jsonResponse.data)
                    })
            })
            .catch((error) => {
                console.log(error);
            })

    }, []);

    const catigoryItems = items.map(item => {
        return (

            <div className="item-list-single-item"
                onClick={() => navigation(`${item.MenuItemID}`)}>
                <div key={item.MenuItemID} >
                    <p className="menu-item-title">{item.displayName}</p>
                    {/* <p className="menu-item-title">{item.description}</p> */}
                    {/* <p className="menu-item-price">{item.price}</p> */}
                    <div class="spacer" ></div>
                </div>
            </div>

        )
    })

    return (
        <>
            <div>
                <MainHeader />
                <div className="body">
                    {/* <h5>Items </h5> */}
                    <div className="item-list-wrapper">
                        {catigoryItems}
                    </div>
                </div>
            </div>


        </>
    );
}

export default ItemList;