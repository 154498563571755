
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Image from '../../../img/MainWebsite/BlackLogo.png';

function Header() {

const [isMenuOpen, setIsMenuOpen] = useState(false);

const toggleMenu = () => {
  setIsMenuOpen(!isMenuOpen);
};

return (
  <header className={'header chrome-background-1'}>
    <img className={'black-header-logo'} src={Image} alt="Image 2" />
    <nav className={'nav'}>
      <div className={` ${isMenuOpen ? 'display-none': 'hamburger'}`} onClick={toggleMenu}>
        ☰
      </div>
      <div className={` ${isMenuOpen ? 'open ': 'menu'}`}>
      <div className={'hamburger'} onClick={toggleMenu}>
        ☰
      </div>
        <div className={'header-option'}><Link className={'blue-link bold no-underline'} to="/">Home</Link></div>
        <div className={'header-option'}><Link className={'red-link bold no-underline'} to="/About-Us">About Us</Link></div>
        <div className={'header-option'}><Link className={'green-link bold no-underline'} to="/Our-Menus">Our Menus</Link></div>
        <div className={'header-option'}><Link className={'red-link bold no-underline'} to="/For-Restaurants">For Restaurants</Link></div>
        <div className={'header-option'}><Link className={'blue-link bold no-underline'} to="/For-Stores">For Stores</Link></div>
        <div className={'header-option'}><Link className={'green-link bold no-underline'} to="/For-Dispensaries">For Dispensaries</Link></div>
        <div className={'header-option'}><Link className={'red-link bold no-underline'} to="/Contact-Us">Contact Us</Link></div>
      </div>
    </nav>
  </header>
);
}

export default Header;